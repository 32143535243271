<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Title</h3>

	<p><i>This help doc is under construction.</i></p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	